@import '../../stylesheet/styles/colors';
@import '../../stylesheet/styles/breakpoints';

header {
    padding: 40px 60px;
    background: none;
    z-index: 1000;
    @include md {
        padding: 20px 30px;
    }
    @include xxs {
        padding: 20px 15px;
    }
}

.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    height: 80px;
    @include xs {
        height: 60px;
    }
}

.logo {
    height: 100%;
    width: auto;
    &:hover {
        cursor: pointer;
    }
    @include xxs {
        max-width: 80%;
        max-height: 60px;
        height: auto;
    }
}

.logo-wrapper {
    width: auto;
    height: 80px;
    @include xs {
        height: 60px;
    }
}

.not-home {
    justify-content: flex-end;
    @include xs {
        height: 80px;
    }
}

nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: -20px;

    @include md {
        margin-right: 0;
    }
}

.navigation-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    li {
        margin: 0 24px;

        @include lg {
            margin: 0 1rem;
            font-size: 1.05rem;
        }

        a {
            text-decoration: none;
            color: black;
        }
    }
}

.nav-items-drawer {
    flex-direction: column;
    padding-top: 20px;

    li {
        margin: 15px 0;
        @include sm {
            margin: 10px 0;
        }
    }
}

.drawer-wrapper {
    position: fixed;
    top: 0;
    right: -100vw;
    height: 100vh;
    z-index: 1200;
    transition: right .3s ease-in;
    background: $yellow;
    padding: 55px 45px 0 20vw;
    box-sizing: border-box;

    .drawer-inner {
        text-align:right;
    }

    @include sm {
        padding: 40px 35px 50px 20vw;
    }

    @include xs {
        padding: 30px 30px 30px 20vw;
        overflow-y: scroll;
    }
}

.drawer-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.65);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out;
}

.drawer-toggle-button {
    transition: transform .3s ease;
    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
    @include xxs {
        max-width: 15vw;
    }
}

.close-icon {
    transition: transform .3s ease;
    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.navigation-item {
    font-size: 1.2rem;
    &:hover {
        cursor: pointer;
        font-weight: 600;
    }
}