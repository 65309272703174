@import './breakpoints';
@import './colors';

button {
    color: black;
    font-size: 1rem;
    font-family: 'SpaceGrotesk-Regular', 'Roboto-Medium', sans-serif;
    font-weight: 600;
    padding: 5px 18px;
    transition: .3s;

    @include md {
        font-size: 16px;
        padding: 6px 12px;
    }
  
    &:hover {
      cursor: pointer;
    }
}

.outlined-button {
    border: 2px solid $yellow;
    background: transparent;
}

.yellow-button {
    border: none;
    background-color: $yellow;
}