@import '../../../stylesheet/styles/breakpoints';

.image-map {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    background-size: cover;
    background-image: url('../../../assets/img/Components/InteractiveHouses/interactive_houses_background.jpg');
    box-sizing: border-box;

    .image-map-content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
    }
    
    .image-map1, 
    .image-map2, 
    .image-map3, 
    .image-map4, 
    .image-map5, 
    .image-map6,
    .image-map7,
    .image-map8,
    .image-map9,
    .image-map10,
    .image-map11 {
        transition-delay: 0.04s;

        &:hover, &:focus, &:active {
            opacity: 1;
            cursor: pointer;
            
            .cls-1 {
                opacity: 0.7;
                transition-delay: 0;
            }
        }
    }

    .image-map1-stroke, 
    .image-map2-stroke, 
    .image-map3-stroke, 
    .image-map4-stroke, 
    .image-map5-stroke, 
    .image-map6-stroke,
    .image-map7-stroke,
    .image-map8-stroke,
    .image-map9-stroke,
    .image-map10-stroke,
    .image-map11-stroke {
        transition-delay: 0.07s;

        &:hover, &:focus, &:active {
            opacity: 1;
            transition-delay: 0.04s;
            cursor: pointer;
        }
    }

    .sold {
        &:hover, &:focus, &:active {
            cursor: initial;
        }
    }
}


/**
 * If HouseChoice component is called from landing page the background image should be original (bigger/higher)
 *
.home-image-map {
    background-image: url('../../assets/images/new-image-map.jpg');
}*/

