@import '../../../stylesheet/styles/breakpoints';
@import '../../../stylesheet/styles/colors';


.bg-for-large-screens {
    background-color: $yellow;
    width: 100%;
}

.house-features-section {
    width: 100%;
    height: calc(100vw / 3.5);
    max-width: 1200px;
    max-height: calc(1200px / 3.5);
    display: flex;
    flex-direction: row;
    margin: 0 auto ;

    .house-feature {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $yellow;
        font-size: calc(1.35rem + .25vw);

        p {
            font-family: 'SpaceGrotesk-Medium' !important;
        }

        &:nth-of-type(2) {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                height: 75%;
                width: 1px;
                border-left: 1px solid rgba(255,255,255,.5);
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                height: 75%;
                width: 1px;
                border-right: 1px solid rgba(255,255,255,.5);
            }
        }

        p {
            margin: 0;
        }
    }
}

.virtual-guide-and-video-section {
    width: calc(100% - 120px);
    margin: 0 auto 100px auto;
    max-width: 1200px;

    @include md {
        width: calc(100% - 60px);
        margin: 0 auto 50px auto;
    }

    @include xxs {
        width: calc(100% - 30px);
    }

    .virtual-guide-article-wrapper {
        margin: 0;
        h1 {
            padding-left: 60px;

            @include sm {
                padding-left: 0px;
                font-size: 1.4rem;
            }
        }
    }

    .virtual-guide-article-content {
        border-left: 5px solid $yellow;
        border-bottom: 5px solid $yellow;
        padding: 0 0 60px 60px;

        @include md {
            padding: 0 0 40px 40px; 
        }

        @include sm {
            padding: 0;
            border: none;
            margin-bottom: 30px;
        }
    }

    .house-virtual-guide-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .video-article-wrapper {
        border-bottom: 5px solid $yellow;
        border-right: 5px solid $yellow;
        margin: 0;
        padding: 30px 60px 100px 0;

        @include sm {
            padding: 0;
            border: none;
        }

        h1 {
            margin: 0 0 20px 0;
            @include sm {
                font-size: 1.4rem;
            }
        }
    }
}

.house-detail-gallery-title {
    width: calc(100% - 120px);
    margin: 0 auto 0 auto;
    max-width: 1200px;

    @include md {
        width: calc(100% - 60px);
        margin: 0 auto 20px auto;
    }

    @include xxs {
        width: calc(100% - 30px);
    } 
}

.ground-plan-gallery {
    width: calc(100% - 120px);
    margin: 30px auto 50px auto;
    max-width: 1200px;

    @include md {
        width: calc(100% - 60px);
        margin: 0 auto 30px auto;
    }

    @include xxs {
        width: calc(100% - 30px);
    }

    img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }
}