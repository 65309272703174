@import '../../../stylesheet/styles/breakpoints';
@import '../../../stylesheet/styles/colors';

.info-section {
    max-width: 1200px;
    width: calc(100% - 120px);
    margin: 0 auto;
    padding: 80px 0 0 20px;
    @include md {
        width: calc(100% - 60px);
        padding: 40px 0 0 20px;
    }
    @include xs {
        padding: 28px 0 0 0;
    }
    @include xxs {
        width: calc(100% - 30px);
    }
}

.info-container {
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    margin-top: 40px;
    @include sm {
        flex-direction: column;
        margin-top: 20px;
    }
}

.left-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include sm {
        padding: 10px 0;
        border-top: 3px solid $yellow;
        border-bottom: 3px solid $yellow;
    }
}

.right-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 100px;
    @include sm {
        margin: 10px 0 0 0;
        padding-top: 10px;
    }
}