@import '../../../stylesheet/styles/colors';
@import '../../../stylesheet/styles/breakpoints';

.blogpost {
    width: calc(100% - 120px);
    max-width: 1200px;
    margin: 100px auto;

    @include md {
        width: calc(100% - 60px);
    }

    @include sm {
        margin: 50px auto;
    }

    @include xxs {
        width: calc(100% - 30px);
    }
}

.article-content {
    padding: 5px 0 50px 20px;
    border-left: 3px solid $yellow;

    @include sm {
        border-left: none;
        padding: 0 0 30px 0;
    }

    h2 {
        margin-top: 0;
    }

    p {
        max-width: 80ch;
    }
}

.article-text {
    max-width: 70ch;
}

.article-images-container {
    width: 100%;
    padding: 40px;
    padding-left: 20px;
    border: 3px solid $yellow;
    border-left: none;

    @include md {
        padding: 30px 30px 30px 20px;
    }

    @include sm {
        padding: 0;
        border: none;
    }

    img {
        width: 100%;
        height: 100%;
        @include sm {
            outline: 3px solid $yellow;
        }
    }
}