@import '../../stylesheet/styles/breakpoints';


.image-gallery-container {
    width: calc(100% - 120px);
    margin: 30px auto 50px auto;
    max-width: 1200px;

    @include md {
        width: calc(100% - 60px);
    }

    @include xxs {
        width: calc(100% - 30px);
    }

    ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1rem;
        grid-auto-flow: dense;
        width: 100%;
        list-style: none;
        padding: 0;

        @include xs {
            grid-gap: .5rem;
            display: flex;
            flex-direction: column;
        }

        li {
            &:nth-child(7n-6) {
                grid-column: span 3;
                grid-row: span 4;
            }

            &:nth-child(7n-5) {
                grid-column: span 1;
                grid-row: span 4;
            }

            &:nth-child(7n-4) {
                grid-column: span 4;
                grid-row: span 5;
            }
            &:nth-child(7n-3) {
                grid-column: span 2;
                grid-row: span 6;
            }
            &:nth-child(7n-2) {
                grid-column: span 2;
                grid-row: span 4;
            }
            &:nth-child(7n-1) {
                grid-column: span 2;
                grid-row: span 2;
            }
            &:nth-child(7n) {
                grid-column: span 4;
                grid-row: span 5;
            }
        }

        .gallery-single-img-wrapper {
            height: 100%;
            margin: 0;
            position: relative;

            &:hover{
                cursor: pointer;
                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url('../../assets/img/icons/magnifying_glass_icon.svg') no-repeat, 
                    rgba(0,0,0,0.5);
                    background-position: center;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
    }


    }
    
    
    
    