@import '../../stylesheet/styles/breakpoints';
@import '../../stylesheet/styles/colors';

footer {
    padding: 40px 60px;
    background-color: $yellow;
    width: 100%;
    @include md {
        padding: 40px 33px;
    }
}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1204px;
    width: 100%;
    margin: 0 auto;

    @include xs {
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
        margin: 0 auto;
    }

    .footer-logo-zelesice-container{
        display: flex;
        flex: 1 1 0;
        margin-right: 10px;

        .footer-logo-img {
            height: 80px;

            @include md {
                height: 65px;
            }

            @include sm {
                height: 55px;
            }

            @include xs {
                height: 80px;
            }

            @include xxs {
                height: auto;
                width: 100%;
            }
        }
    }

    .footer-logos-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1 1 auto;

        @include xs {
            order: 3;
        }

        a {
            height: 40px;
        }

        img {
            height: 40px;
        }

        .footer-logos-inner {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;

            @include lg {
                flex-direction: column;
                align-items: flex-start;
                width: auto;
    
                img {
                    height: 35px;
                    margin: 5px 0;

                    @include md {
                        height: 30px;
                    }

                    @include sm {
                        height: 25px;
                    }

                    @include xs {
                        height: 35px;
                        max-width: 100%;
                    }
                    
                    @include xxs {
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    height: 100%;

    @include xs {
        margin: 30px 0;
    }

    p {
        margin: 0;
        font-family: 'SpaceGrotesk-Medium' !important;

        @include sm {
            font-size: .85rem;
        }
    }

    .footer-content-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        align-items: flex-end;

        @include md {
            font-size: .9rem;
        }

        @include sm {
            flex-direction: column-reverse;
            align-items: flex-start;
            width: auto;

            p {
                &:nth-of-type(2) {
                    margin-bottom: 15px;
                }
            }
        }

        @include xs {
            p {
                font-size: 1rem;
            }
        }
    }
}