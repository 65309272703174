@import '../../../stylesheet/styles/breakpoints';

.about-project-section {
    width: calc(100% - 120px);
    margin: 80px auto;
    max-width: 1200px;
    @include md {
        width: calc(100% - 60px);
        margin: 50px auto;
    }
    @include xxs {
        width: calc(100% - 30px);
    }

    .about-text-editor-container {
        max-width: 70ch;
    }

}

.about-hajany-section {
    width: calc(100% - 120px);
    margin: 100px auto 100px auto;
    max-width: 1200px;
    @include md {
        width: calc(100% - 60px);
    }
    @include sm {
        margin: 50px auto;
    }
    @include xxs {
        width: calc(100% - 30px);
    }

    p {
        max-width: 50ch;
        margin-bottom: 20px;
    }
}

.hajany-imgs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 40px 0 0 0;

    @include xs {
        display: flex;
        flex-direction: column ;
    }

    img {
        width: 100%;
        height: 100%;
    }

    #large-img {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }
}