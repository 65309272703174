@import '../../../stylesheet/styles/colors';

table {
    width: 100%;
    display: block;
    overflow-x: auto;

    tr {
        position: relative;
        font-weight: 400;
        
        &::before {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            border-top: 2px solid $yellow;
        }
    }

    thead {
        tr{
            &:nth-of-type(1)::before {
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
                border-top: 0px solid $yellow;
            }
        }
    }

    tbody {
        a {
            text-decoration: none;
            color: black;
        }

        .hoverable {
            &:hover {
                background-color: rgba(226, 211, 0, 0.07);
                cursor: pointer;
            }
        }
    }


    th {
        text-align: start;
        padding-bottom: 10px;
    }
    
    td {
        padding: 7px 0;
        min-width: 195px;
        
    }
}