@import '../../../stylesheet/styles/breakpoints';
@import '../../../stylesheet/styles/colors';
@import '../../../stylesheet/styles/_buttons.scss';

.tooltip {
    width: fit-content !important;
    height: fit-content !important;
    min-width: 180px;
    padding: 20px 30px !important;
    background-color: white !important;
    opacity: 1 !important;
    color: black;
    font-size: 20px !important;
    pointer-events: auto !important;
    border: 2px solid $yellow !important;

    @media (max-width: 800px) {
        width: 160px  !important;
        font-size: 16px !important;
    }

    @include md {
        width: 150px;
        padding: 16px 12px !important;
    }

    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
    }

    .info-grid {
        margin: 15px 0px;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto;
        grid-gap: 10px;
        row-gap: 15px;
    }

    #floor-area-icon {
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-left: 15px;
        border: 1px solid $yellow;
        border-radius: 1px;
        align-self: center;
    }

    #floor-area {
        grid-column: 3 / 4;
        grid-row: 1 / 1;
    }

    #lot-area-icon {
        grid-column: 1 / 3;
        grid-row: 2 / 2;
        border: 1px solid $yellow;
        position: relative;
        max-width: 90px;
        &::before {
            content: "";
            width: 15px !important;
            height: 15px !important;
            display: inline-block;
            background-color: white;
            border: 1px solid $yellow;
            border-radius: 1px;
            position: absolute;
            top: -5px;
            left: 10px;

        }
    }

    #lot-area {
        grid-column: 3 / 4;
        grid-row: 2 / 2;

    }

    #price {
        grid-column: 1 / 4;
        grid-row: 3 / 3;
    }

    img {
        width: 30px;

        @include lg {
            width: 24px;
        }
    }

    h1 {
        color: black !important;
        margin: 0;
        width: 100%;
        padding-bottom: 5px !important;
        font-size: 36px;
        border-bottom: 2px solid $yellow !important;

        @media (max-width: 1400px) {
            font-size: 28px;
        }

        @include lg {
            font-size: 32px;
        }

        @include md {
            font-size: 28px;
        }
    }

    p {
        color: black;
        margin: 0;

        span {
            color: black;

            @include md {
                font-size: 18px;
            }
        }

        @include lg {
            font-size: 18px;
        }

        @include md {
            font-size: 16px;
        }
    }

    a {
        text-decoration: none;
    }

    .row {
        border-bottom: 1px solid white;
        min-height: 44px;

        @include lg {
            min-height: 40px;
        }

        @include md {
            min-height: 36px;
        }

        @include sm {
            min-height: 36px;
        }
        &:first-of-type {
            border-bottom: 3px solid white;
        }
        &:last-of-type {
            border-bottom: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .col-xs-3, .col-xs-9 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .custom-button {
        margin-top: 0 !important;
        font-size: 18px !important;
    }
    
    @media (max-width: 1400px) {
        .custom-button {
            font-size: 14px !important;
        }
    }
}
